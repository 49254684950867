import Vue from "vue";
import Router from "vue-router";
import NotFound from "./pages/_notFound.vue";
import store from "./store";
import { vueRouterInstrumentation } from "@sentry/vue";
import stepper from "./plugins/stepper";
import axios from "axios";

Vue.use(Router);

const router = new Router({
  mode: window.cordova?.platformId ? "hash" : "history",
  base: import.meta.env.VITE_BASE_URL || "/",
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash, behavior: "smooth" };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: "/",
      components: {
        default: () => import("./pages/_layout.user.vue"),
      },
      children: [
        {
          path: "/",
          components: {
            default: () => import("./pages/home.vue"),
          },
          meta: {
            reloadOnResume: true,
          },
        },
        {
          path: "/logout",
          components: {
            default: () => import("./pages/logout.vue"),
          },
          meta: {
            public: true,
            hideMenus: true,
          },
        },
        {
          path: "/signin",
          components: {
            default: () => import("./pages/signin.vue"),
          },
          meta: {
            public: true,
            systembar: true,
          },
          beforeEnter: (to, from, next) => {
            if (store.state.me) {
              next("/");
            } else {
              next();
            }
          },
        },
        {
          path: "/signin/:slug",
          components: {
            default: () => import("./pages/signin.vue"),
          },
          meta: {
            public: true,
          },
          beforeEnter: (to, from, next) => {
            if (store.state.me) {
              next("/");
            } else {
              next();
            }
          },
        },
        {
          path: "/profile",
          components: {
            default: () => import("./pages/profile/profile.vue"),
          },
        },
        {
          path: "/rankings",
          components: {
            default: () => import("./pages/rankings.vue"),
          },
          meta: {
            reloadOnResume: true,
          },
        },
        {
          path: "/rankings/organization-details",
          components: {
            default: () => import("./pages/rankings.vue"),
          },
          meta: {
            reloadOnResume: true,
          },
        },
        {
          path: "/feed/:category",
          components: {
            default: () => import("./pages/feed.vue"),
          },
          meta: {
            reloadOnResume: true,
          },
        },
        {
          path: "/feed/:category/filter/:challengeId",
          components: {
            default: () => import("./pages/feed.vue"),
          },
          meta: {
            reloadOnResume: true,
          },
        },
        {
          path: "/feed/:category/add/photo/challenge/:challengeId",
          components: {
            default: () => import("./pages/feed.vue"),
            dialog: () => import("./components/photo-recorder.vue"),
          },
        },
        {
          path: "/myStats",
          components: {
            default: () => import("./pages/myStats.vue"),
          },
          meta: {
            reloadOnResume: true,
          },
        },
        {
          path: "/stats",
          components: {
            default: () => import("./pages/stats.vue"),
          },
          meta: {
            reloadOnResume: true,
          },
        },
        {
          path: "/news/:newsId",
          components: {
            default: () => import("./pages/home.vue"),
            dialog: () => import("./components/news-popup.vue"),
          },
          meta: {
            reloadOnResume: true,
          },
        },
        {
          path: "/team/:teamId",
          components: {
            default: () => import("./pages/rankings.vue"),
          },
        },
        {
          path: "/add/activity",
          components: {
            default: () => import("./pages/home.vue"),
            dialog: () => import("./components/activity-recorder.vue"),
          },
        },
        {
          path: "/add/activity/:date",
          components: {
            default: () => import("./pages/myStats.vue"),
            dialog: () => import("./components/activity-recorder.vue"),
          },
        },
        {
          path: "/select-team",
          components: {
            default: () => import("./pages/onboarding/selectTeam.vue"),
          },
          meta: {
            hideMenus: true,
          },
          beforeEnter: async (to, from, next) => {
            const allowCreate = store.state.me.organization_organization?.allow_create_team;
            const teams_count = await axios.get(`/api/teams/${store.state.me.organization}/count`);
            if (allowCreate) {
              next();
            } else if (!allowCreate && teams_count > 0) {
              next();
            } else {
              next("/");
            }
          },
        },
        {
          path: "/change-team",
          components: {
            default: () => import("./pages/onboarding/selectTeam.vue"),
          },
          meta: {
            hideMenus: true,
          },
          beforeEnter: async (to, from, next) => {
            const allowCreate = store.state.me.organization_organization?.allow_create_team;
            const teams_count = await axios.get(`/api/teams/${store.state.me.organization}/count`);
            if (allowCreate) {
              next();
            } else if (!allowCreate && teams_count > 0) {
              next();
            } else {
              next("/profile");
            }
          },
        },
        {
          path: "/notifications",
          components: {
            default: () => import("./pages/onboarding/notifications.vue"),
          },
          meta: {
            hideMenus: true,
          },
          beforeEnter: (to, from, next) => {
            if (store.state.isNotificationsEnabled) {
              next("/activity");
            } else {
              next();
            }
          },
        },
        {
          path: "/data-sharing-permission",
          components: {
            default: () => import("./pages/onboarding/dataSharingConsent.vue"),
          },
          meta: {
            hideMenus: true,
          },
          beforeEnter: (to, from, next) => {
            if (
              store.state.me.is_participant !== false &&
              (store.state.me?.event_event?.enable_data_sharing_authorization_popup ||
                store.state.me?.organization_organization?.enable_data_sharing_authorization_popup)
            ) {
              store.state.me?.data_collection_permission !== null
                ? next(to.query.next?.toString() || store.state.me.team ? "/" : "/select-team")
                : next();
            } else {
              next("/");
            }
          },
        },
        {
          path: "/activity",
          components: {
            default: () => import("./pages/onboarding/activity.vue"),
          },
          meta: {
            hideMenus: true,
          },
          beforeEnter: async (to, from, next) => {
            const isAvailable = await stepper.isAvailable();
            const next_url =
              store.state.me.is_participant !== false ? "/data-sharing-permission" : to.query.next?.toString() || "/";

            if (!isAvailable) next(next_url);
            if (
              store.state.me.event_event.enable_pedometer === false ||
              store.state.isPedometerEnabled === true ||
              !isAvailable
            ) {
              next(next_url);
            } else {
              next();
            }
          },
        },
      ],
    },
    {
      path: "/public",
      components: {
        default: () => import("./pages/_layout.public.vue"),
      },
      meta: {
        public: true,
      },
      children: [
        {
          path: "/public/wall/event/:eventId",
          components: {
            default: () => import("./components/public-wall.vue"),
          },
        },
        {
          path: "/public/wall/event/:eventId/photo/:photoId",
          components: {
            default: () => import("./components/public-wall.vue"),
            dialog: () => import("./components/photo-carousel.vue"),
          },
        },
        {
          path: "/public/wall/event/:eventId/organization/:organizationId",
          components: {
            default: () => import("./components/public-wall.vue"),
          },
        },
        {
          path: "/public/wall/event/:eventId/organization/:organizationId/photo/:photoId",
          components: {
            default: () => import("./components/public-wall.vue"),
            dialog: () => import("./components/photo-carousel.vue"),
          },
        },
        {
          path: "/public/wall/event/:eventId/category/:categoryId",
          components: {
            default: () => import("./components/public-wall.vue"),
          },
        },
        {
          path: "/public/wall/event/:eventId/category/:categoryId/photo/:photoId",
          components: {
            default: () => import("./components/public-wall.vue"),
            dialog: () => import("./components/photo-carousel.vue"),
          },
        },
      ],
    },
    {
      path: "/*",
      component: NotFound,
      meta: {
        public: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.query && to.query.auth) {
    // Login as the user in shadow mode
    store.commit("LOADING", true);
    store.dispatch("LOGOUT").then(function () {
      store.commit("AUTH", { access_token: to.query.auth, shadow: true });
      let query = Object.assign({}, to.query);
      delete query.auth;
      setTimeout(function () {
        // @ts-ignore
        location.search = new URLSearchParams(query).toString();
      }, 500);
    });
    return;
  }
  if (isPublicPath(to)) {
    return next();
  }
  store
    .dispatch("AUTHENTICATE")
    .then((me) => {
      if (me) next();
      else next("/signin");
    })
    .catch((err) => {
      console.error("Fail to authenticate", err);
      next("/logout");
    });
});

vueRouterInstrumentation(router);

const isPublicPath = (to) => {
  for (let i = to.matched.length - 1; i >= 0; i--) {
    if (to.matched[i].meta.public !== undefined) {
      return to.matched[i].meta.public;
    }
  }
  return null;
};

document.addEventListener("deviceready", () => {
  window?.["plugins"]?.["launchmyapp"]?.getLastIntent(function (url) {
    if (url) {
      console.log("Got an intent", url);
      window["plugins"]["launchmyapp"].clearIntent();
      router
        .push(url.replace(/^[a-zA-Z]+:\/\/((www\.)?(go|event)(\.[a-z]+)?\.alvarum\.com\/)?\/?/, "/"))
        .catch(() => {});
    }
  });
});
window["handleOpenURL"] = (url) => {
  console.log("handleOpenURL", url);
  router.push(url.replace(/^[a-zA-Z]+:\/\/((www\.)?(go|event)(\.[a-z]+)?\.alvarum\.com\/)?\/?/, "/")).catch(() => {});
};

export default router;
